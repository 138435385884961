import React from "react";
import { ApplicationListRenderer } from "ui";
import { Wrapper } from "ui";

export default function Applications() {
  return (
    <Wrapper>
      <div>
        <div className="text-xl h-16">Applications</div>
        <ApplicationListRenderer />
      </div>
    </Wrapper>
  );
}
